.navbar {
  @apply w-full bg-white shadow-md;
  position: relative;
  z-index: 1000;
}

.navbar-content {
  @apply flex justify-between items-center h-16 px-4 sm:px-6 lg:px-8;
}

.navbar-left {
  @apply flex;
}

.navbar-logo {
  @apply flex-shrink-0 flex items-center;
}

.logo-image {
  @apply h-16 w-auto;
}

.navbar-menu {
  @apply hidden lg:flex space-x-8 px-8;
}

.menu-text {
  @apply text-gray-500 hover:text-gray-900 cursor-pointer text-sm font-medium;
}

.mega-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  display: flex;
  justify-content: space-around;
  padding: 2.5rem; 
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  transition: opacity 0.3s cubic-bezier(0.25, 0.1, 0.25, 1), visibility 0.3s cubic-bezier(0.25, 0.1, 0.25, 1), transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
  pointer-events: none;
}
.menu-item{
  @apply mx-auto ml-4;
}
.menu-item:hover .mega-menu {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  pointer-events: auto;
}

.mega-menu-column {
  flex: 1;
  padding: 0 1.5rem; 
}

.mega-menu-title {
  @apply text-xl font-semibold mb-3 text-gray-800; 
}

.mega-menu-list {
  @apply space-y-3; 
}

.mega-menu-item {
  @apply text-base; 
}

.mega-menu-link {
  @apply text-gray-600 hover:text-gray-900 py-1 block; 
}

.navbar-right {
  @apply hidden sm:ml-6 sm:flex sm:items-center;
}

.icon-text {
  @apply p-1 text-gray-400 hover:text-gray-500;
}

.icon {
  @apply h-6 w-6;
}

.mobile-menu-text {
  @apply -mr-2 flex items-center sm:hidden;
}

.mobile-menu {
  @apply sm:hidden;
}

.mobile-menu-items {
  @apply pt-2 pb-3 space-y-1;
}

.mobile-menu-item {
  @apply block pl-3 pr-4 py-2 border-l-4 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300;
}

.mobile-submenu-title {
  @apply block pl-6 pr-4 py-1 text-sm font-medium text-gray-400;
}

.mobile-submenu-item {
  @apply block pl-9 pr-4 py-2 text-sm font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-50;
}

.mobile-menu-icons {
  @apply pt-4 pb-3 border-t border-gray-200 flex items-center px-4;
}

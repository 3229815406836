@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}


.App-header {
  background-color: #F5F5DC;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #333333;
}

.App-link {
  color: #61dafb;
}


